
.TabsContainer {
    width: 100%;
    margin: 0;
    // margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    // overflow-x: auto;
    align-items: center;
    
    white-space: nowrap;

    // &::-webkit-scrollbar {
    //     height: 3px;
    // }

    // &::-webkit-scrollbar:hover {
    //     height: 3px;
    // }

    // &::-webkit-scrollbar-track {
    //     background-color: lightgrey;
    //     color: black;
    // }

    // &::-webkit-scrollbar-thumb {
    //     box-shadow: inset 0 0 6px black;
    // }

    .tabs {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        

        &.right {
            justify-content: right;
        }

        &.left {
            justify-content: left;
        }

        &.space-between {
            justify-content: space-between;
        }

        .tab {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            user-select: none;
            border-radius: 25px;
            padding: 5px 5px;
            margin: 5px 0px;

            
            


            &.active:hover,
            &.active {
                font-weight: 500;
                // background: linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%) !important;
                background-color: #D43737 !important;
                
                p.label {
                    color: white;
                    font-size: 1.1rem;
                }

                .iconContainer {
                    span.icon {
                        display: none;
                    }
                    
                    span.active_icon {
                        display: inline-flex;
                    }
                    .shadowContainer {
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 2;
                        border-radius: 30px;
                    }

                }
            }

            &:hover {
                background: linear-gradient(90deg, rgba(200,200,200,1) 0%, rgba(157,157,157,1) 35%);
                p.label {
                    // color: rgb(0, 159, 43);
                }
            }

            

            .iconContainer {
                display: flex;
                flex-direction: row;

                span.icon,
                span.active_icon {
                    margin-right: 5px;
                    margin-top: 2px;
                }

                span.icon {
                    display: inline-flex;
                }

                span.active_icon {
                    display: none;
                }

            }


            p.label {
                margin: 0;
                color: white;
                font-size: 1.1rem;
                white-space: nowrap;
            }
        }
    }


    hr.border {
        width: 100%;
        margin: 0;
        border: none;
        border-top: 1px solid #dee2e6;
        position: relative;
        bottom: 1px;
    }
    
}
