/* Ant-d datepicker styles */
.ant-picker{
    background-color: #161616 !important;
    border: 0px solid #48556F !important;
    border-radius: 18px !important;
    color: white !important;
    font-size: 16px !important;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    line-height: 1.4 !important;
    padding: 10px;
    margin: 4px 0px 8px 0px;
    width: 100%;
    /* height: 50px; */
    font-family: 'Gilroy';
}

.ant-picker .ant-picker-input > input{
color: white;
font-size: 18px;
padding: 6px !important;
}::placeholder{
    color: white !important;

}

.ant-picker-suffix{
    color: white !important;
}