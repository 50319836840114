.BorderedCard {
    width: 100%;
    position: relative;
    border-radius: 18px;
    background: #222232;
    overflow: hidden;
    padding: 1.05px;

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 18px;
        padding: 0.6px;
        // background: linear-gradient(123.9deg, #29A5DE 24.41%, #2B3C8E 94.01%);
        background-color:#D43737;
        -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }

}