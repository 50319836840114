.Payment {
    width: 100%;
    height: fit-content;
    padding: 20px;
}

.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    padding-right: 10px;
}

.paymentTypeCard {
    background: #202127;
    height: 120px;
}

.active {
    border: 1px solid #D43737;
}

.paymentCard {
    background: linear-gradient(180deg, #D43737 20%, #B41B1B 55%);
    border: 1px solid #F1C256;
    height: 170px;
}

.Input {
    background-color: #161616 !important;
    border: 0px solid #48556F !important;
    border-radius: 18px !important;
    color: white !important;
    font-size: 18px !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    line-height: 1.4 !important;
    /* padding-left: 40px !important; */
    font-size: larger;
}

.Input::placeholder {
    /* color: #8F8F8F !important; */
    color: white !important;
}

.select {
    background-color: #161616 !important;
    border: 0px solid #48556F !important;
    border-radius: 18px !important;
    color: white !important;
    font-size: 16px !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    line-height: 1.4 !important;
    padding: 10px;
    margin: 4px 0px 8px 0px;
    width: 100%;
    /* height: 50px; */
    font-family: 'Gilroy';
}

.select::placeholder {
    color: white !important;
}

.select:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
}

.select:required:invalid {
    color: white !important;
}

option {
    color: white;
}


.datePicker {
    background-color: #161616 !important;
    border: 0px solid #48556F !important;
    border-radius: 18px !important;
    color: white !important;
    font-size: 16px !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    line-height: 1.4 !important;
    padding: 10px;
    margin: 5px 0px 15px 0px;
    width: 100%;
    font-family: 'Gilroy';
}

.datePicker::-webkit-calendar-picker-indicator {
    filter: invert(1);
}


@media (max-width:991px) {}

@media (max-width:767px) {
    .connectCoinBox {
        margin-top: 10px;
    }
}


.spanNoDataFound {
    color: red;
    font-weight: 500;
    font-size: 16px;
}

.addCard {
    width: 25%;
}

@media screen and (max-width: 500px) {
    .addCard {
        width: 100%;
    }
}

.CardDetails {
    color: white;
    font-weight: 500;
    font-size: 16px;
}