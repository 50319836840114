

.Instruction{
    width: 100%;
    height: fit-content;
    padding: 20px;
}

.headerContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    padding-right: 10px;
}

.tabBar {
    max-height: 300px; /* Set max height for custom scrollbar */
    overflow-y: auto; /* Enable vertical scrolling */
}
  /* Customize as needed */
.tabBar .nav-link {
    padding: 0.5rem;
}

.tabBar img {
    width: 100px;
    height: 100px;
    /* Add any additional styling for the images */
}

.contentSubHeading{
    color: #D43737;
}

.contentParagraph{
    max-height: 400px;
    overflow: auto;
    
}
.contentParagraph::-webkit-scrollbar {
  width: 20px;
  display: block !important;
}

/* width */
.contentParagraph::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .contentParagraph::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px white;  */
    border-radius: 10px;
    background-color: white;
  }
  
  /* Handle */
  .contentParagraph::-webkit-scrollbar-thumb {
    background: #D43737; 
    border-radius: 10px;
    width: 20px;
    height: 5px;
  }
  
  /* Handle on hover */
  .contentParagraph::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }



@media (max-width:991px) {

}