.Dashboard {
    width: 100%;
    height: fit-content;
}

.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    padding-right: 10px;
}

.gameCard {
    width: 100%;
    position: relative;
    cursor: pointer;
}

.gameImage {
    width: 100%;
    aspect-ratio: 14/8;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 30px;
}

.shadowContainer {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    border-radius: 30px;
}

.content {
    width: 100%;
    padding: 0 15px 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: start;
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    color: white;
}

.comingSoonCard {
    width: 100%;
    position: relative;
    background-color: #202127;
    border-radius: 30px;
    height: 100%;
    /* height: 225px; */
    padding-top: 32px;
    aspect-ratio: 14/8;
}

.comingSoonContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    color: white;
    padding: 15px;
    font-size: 2.5rem;
    /* position: absolute;
    bottom: 2px;
    left: 2px; */
    /* font-size: 3dvw; */
}

.modalContainer {
    /* padding: 20px; */
    width: 100vw;
    /* height: 100dvh; */
    height: 100vh;
    /* max-width: 500px; */
    /* height: fit-content; */
    /* max-height: 500px; */
    /* max-height: 80vh; */
    /* border-radius: 18px; */
    background-color: #0D0D1C;
    color: white;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    position: relative;
    z-index: 1000;
    overflow: scroll;


}


hr {
    border-top: 1px solid #48556F !important;
    width: 90%;
}

/* @media only screen and (max-width: 768px) {
    .modalContainer{
        max-width: 100vw;
    }
} */

/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
     width: 100vw;
      height: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0; 
    }
  } */

.loadingScreenBackground {
   
}

.progressBarBox {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
}


@media (max-width:991px) {}