/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-top: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100px;
  background-color: #202127;
  border-radius: 20px;
  color: white;
}
/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 35%;
  right: 15px;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a background */
.container input:checked ~ .checkmark {
  background-color: #FCFCFC
  ;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: ""; 
  position: absolute;
  /* display: none; */
  display: block;
  /* background: #F1C256 ; */
  background: #202127;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
  /* background: #202127; */
  background: #F1C256
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
    top: 7px;
    left: 7px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    /* background: #F1C256; */
    background: #202127;
}

.lc-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text{
  font-size: 24px;
}


/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .text{
    font-size: 24px;
  }
}

/* If the screen size is 600px or less, set the font-size of <div> to 30px */
@media only screen and (max-width: 600px) {
  .text{
    font-size: 18px;
  }
}