.select{
    background-color: #202127 !important;
    border: 0px solid #48556F !important;
    border-radius: 18px !important;
    color: white !important;
    font-size: 16px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    line-height: 1.4 !important;
    padding: 10px;
    margin: 4px 0px 8px 0px;
    width: 100%;
    height: 50px;
    font-family: 'bigerover';
}

/* .select::placeholder {
    color: #8F8F8F !important;
} */

.select:focus{
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

.select:required:invalid { color: #8F8F8F !important; }

option {
    color: white;
}