.Profile{
    width: 100%;
    height: fit-content;
    padding: 20px;
}

.headerContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    padding-right: 10px;
}

.userContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.image {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border: 4px solid #D43737
}

.uploadBtn{
    background-color: #202127;
    border-radius: 12px;
    padding: 8px;
    color: #F1C256;
    font-size: 14px;
    margin-top:8px;
    cursor: pointer;
}

.nameContainer {
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.userName{
    color: white;
    font-weight: bold;
    font-size: 30px;
    padding:5px;
}

.showCoins{
    width: 100%;
    height: fit-content;
    background-color: #202127;
    border-radius: 16px;
    margin-left: 40px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dc{
    background-color: #F1C256;
    border-radius: 16px;
    padding: 16px;
    font-size: 20px;
    width:fit-content;   
}

.plusIcons{
    background-color: #161616;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8F8F8F;
    cursor: pointer;
    font-size: 1.3rem;
    margin: 5px 5px;
}

/* .avatarContainer {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 20px;
  }
  
  .avatarContainer .row {
    flex-wrap: nowrap;
    margin-bottom: 0;
  }

.avatarCol {
    text-align: center;
    padding: 10px;
} */

.avatarImg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.select{
    background-color: #202127 !important;
    border: 0px solid #48556F !important;
    border-radius: 18px !important;
    color: #8F8F8F !important;
    font-size: 16px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    line-height: 1.4 !important;
    padding: 35px;
    margin: 4px 0px 8px 0px;
    width: 100%;
    height: 50px;
    font-family: 'Gilroy';
}

/* .select::placeholder {
    color: #8F8F8F !important;
} */

.select:focus{
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

.select:required:invalid { color: #8F8F8F !important; }

option {
    color: white;
}

/* hide arrow of select field */
.select {
    -webkit-appearance: none;
    -moz-appearance: none;
    /* text-indent: 1px; */
    /* text-overflow: ''; */
  }




@media (max-width:991px) {

}