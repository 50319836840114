.Currency {
    width: 100%;
    height: fit-content;
    padding: 20px;
}

.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    padding-right: 10px;
}

.showCoins {
    width: 100%;
    height: fit-content;
    background-color: #202127;
    border-radius: 16px;
    /* margin-left: 40px; */
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dc {
    background-color: #F1C256;
    border-radius: 16px;
    padding: 16px;
    font-size: 20px;
    width: fit-content;
}

.plusIcons {
    background-color: #161616;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8F8F8F;
    cursor: pointer;
    font-size: 1.3rem;
    margin: 5px 5px;
}

.connectCoinBox {
    width: 100%;
    height: fit-content;
    background-color: #F1C256;
    border-radius: 16px;
    padding: 6px;
    text-align: center;
    font-family: 'Bigerover';
    font-size: 18px;
}

.connectCoinBox>p {
    color: #202127 !important;
    line-height: 4.4;
}




@media (max-width:991px) {}

@media (max-width:767px) {
    .connectCoinBox {
        margin-top: 10px;
    }
}



.paymentCard {
    background: linear-gradient(180deg, #D43737 20%, #B41B1B 55%);
    border: 1px solid #F1C256;
    height: 170px;
    cursor: pointer;
}

.active {
    background: linear-gradient(180deg, #a64b4b 20%, #4e1a1a 55%);
    border: 1px solid blue;
}

.spanNoDataFound {
    color: red;
    font-weight: 500;
    font-size: 16px;
}