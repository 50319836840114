

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), url('./fonts/Gilroy/Gilroy-Regular_0.ttf') format('truetype');
}


body {
    margin: 0;
    font-family: 'Gilroy' !important;
}


p{
    color: white;
}